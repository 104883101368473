import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import Reactmarkdown from "react-markdown"

import "./libros.scss"

const LibrosIndex = ({ data }) => {
  const libros = data.strapi.librosTiendas
    .sort((a, b) => b.orden - a.orden)
    .map(x => {
      const modalComprar = id => {
        document.getElementById(id).style.display = "flex"
      }
      const modalComprarCerrar = id => {
        document.getElementById(id).style.display = "none"
        console.log(id)
      }

      const modalComprarCerrarFuera = id => {
        let modal = document.getElementById(id)

        window.addEventListener("click", event => {
          console.log(modal)
          console.log("cerrar", modal)
          if (event.target === modal) {
            modal.style.display = "none"
          }
        })
      }

      return (
        <div>
          <div className="libro-wrapper" key={x.id}>
            <Link to={x.uid ? x.uid : ""}>
              <div>
                <img
                  data-sizes="auto"
                  className="lazyload blur-up"
                  src={`https://res.cloudinary.com/lagexpress/image/upload/w_20,f_auto,q_auto/${x.tapa.provider_metadata.public_id}`}
                  data-src={`https://res.cloudinary.com/lagexpress/image/upload/w_auto,f_auto,q_auto/${x.tapa.provider_metadata.public_id}`}
                  alt={x.tapa.alternativeText ? x.tapa.alternativeText : ""}
                />
              </div>
              <div>
                <p
                  style={{
                    display: x.descripcion ? "block" : "none",
                  }}
                >
                  {x.descripcion}
                </p>
              </div>
            </Link>
            <div className="venta-libros-menu">
              <span
                style={{
                  display: x.prologo ? "block" : "none",
                }}
                className="button"
                role="button"
                onClick={() => modalComprar(`${x.tapa.caption}-prologo`)}
              >
                Prólogo
              </span>
              <span
                style={{
                  display: x.preview ? "block" : "none",
                }}
                className="button"
                role="button"
                onClick={() => modalComprar(`${x.tapa.caption}-preview`)}
              >
                Vista Previa
              </span>
              <span
                style={{
                  display: x.precioF + x.precioD ? "block" : "none",
                }}
                className="button"
                role="button"
                onClick={() => modalComprar(x.tapa.caption)}
              >
                Comprar
              </span>
            </div>
          </div>

          {/* prologo start */}

          <div
            className="modal-prologo"
            id={`${x.tapa.caption}-prologo`}
            onClick={() => modalComprarCerrarFuera(`${x.tapa.caption}-prologo`)}
          >
            <div className="modal-contenido">
              <div className="prologo">
                <div>
                  <Reactmarkdown>{x.prologo}</Reactmarkdown>
                </div>
              </div>

              <div className="botones">
                <span
                  role="button"
                  className="button-cerrar"
                  tabIndex="-3"
                  onClick={() =>
                    modalComprarCerrar(`${x.tapa.caption}-prologo`)
                  }
                  onKeyPress={() =>
                    modalComprarCerrar(`${x.tapa.caption}-prologo`)
                  }
                >
                  CERRAR
                </span>
              </div>
            </div>
          </div>

          {/* prologo end */}

          {/* vista previa start */}

          <div
            className="modal-prologo"
            id={`${x.tapa.caption}-preview`}
            onClick={() => modalComprarCerrarFuera(`${x.tapa.caption}-preview`)}
          >
            <div className="modal-contenido">
              <div
                style={{
                  height: "80vh",
                  width: "90vw",
                }}
              >
                <iframe
                  title="libro"
                  width="100%"
                  height="100%"
                  src={x.preview}
                  frameborder="1"
                  allowtransparency=""
                  allowfullscreen=""
                ></iframe>
              </div>

              <div className="botones">
                <span
                  role="button"
                  className="button-cerrar"
                  tabIndex="-3"
                  onClick={() =>
                    modalComprarCerrar(`${x.tapa.caption}-preview`)
                  }
                  onKeyPress={() =>
                    modalComprarCerrar(`${x.tapa.caption}-preview`)
                  }
                >
                  CERRAR
                </span>
              </div>
            </div>
          </div>

          {/* vista previa end */}

          <div
            className="modal-comprar"
            id={`${x.tapa.caption}`}
            onClick={() => modalComprarCerrarFuera(x.tapa.caption)}
          >
            <div className="modal-contenido">
              <div className="modal-libro">
                <img
                  data-sizes="auto"
                  className="lazyload blur-up"
                  src={`https://res.cloudinary.com/lagexpress/image/upload/w_20,f_auto,q_auto/${x.tapa2.provider_metadata.public_id}`}
                  data-src={`https://res.cloudinary.com/lagexpress/image/upload/w_auto,f_auto,q_auto,r_25/${x.tapa2.provider_metadata.public_id}`}
                  alt={x.tapa2.alternativeText ? x.tapa2.alternativeText : ""}
                />
              </div>

              <div className="alerta">
                <p>El costo de envio de los productos no esta incluido</p>
                <p>Luego de realizar una compra envie un correo electrónico a: eduardoceballos.salta@gmail.com para confirmar el pedido informando el libro que desea comprar y su domicilio.</p>
                <p>Tambien puede hacerlo por WhatsApp: +54 9 387 510-1026</p>
              </div>

              <div className="botones">
                <a className="button" href={x.libroDigital} target="_blank">
                  {x.precioD
                    ? `Comprar Libro en version digital por $${x.precioD}`
                    : "Version digital no disponible."}
                </a>
                <a className="button" href={x.libroFisico} target="_blank">
                  {x.precioF
                    ? `Comprar Libro en version fisica por $${x.precioF}`
                    : "Libro fisico sin stock"}
                </a>
              </div>
              <div className="botones">
                <span
                  role="button"
                  className="button-cerrar"
                  tabIndex="-3"
                  onClick={() => modalComprarCerrar(x.tapa.caption)}
                  onKeyPress={() => modalComprarCerrar(x.tapa.caption)}
                >
                  CERRAR
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    })

  return (
    <Layout>
      <GatsbySeo
        title="Libros"
        titleTemplate="%s | Eduardo Ceballos"
        description="Libros"
        canonical="https://eduardoceballos.com/libros"
        openGraph={{
          url: `https://eduardoceballos.com/libros`,
          title: `Libros`,
          description: `Libros`,
          images: [
            {
              url: `https://eduardoceballos.com/seo/libros.jpg`,
              alt: `Libros`,
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Libros</h1>

      <div className="libros-wrapper">{libros}</div>
      <div>
        <hr />
        <p>
          <span>Eduardo Ceballos ha prologado los siguientes libros:</span>
        </p>
        <ul>
          <li>
            <span>“Socava el Amor” de Mirtha de Wesler, 1986.</span>
          </li>
          <li>
            <span>“Amoralgos” de Antonio Vilariño, 1987.</span>
          </li>
          <li>
            <span>
              “Poemas Transoceánicos” de Rubén Pérez y Chus Feteira, 1993.
            </span>
          </li>
        </ul>
        <p>
          <span>
            Eduardo Ceballos ha dirigido revistas-libros literarias que incluyen
            a los mejores escritores y poetas del Noroeste Argentino:
          </span>
        </p>
        <ul>
          <li>
            <span>
              “De la mano con el Arte” Edición Julio-Agosto-Septiembre de 1985.
            </span>
          </li>
          <li>
            <span>“Logos”, de Diciembre de 1985.</span>
          </li>
          <li>
            <span>“Logos”, de Junio de 1986.</span>
          </li>
          <li>
            <span>“Logos”, de Junio de 1998.</span>
          </li>
        </ul>
        <hr />
      </div>

      <div className="libros-wrapper-old">
        <img
          data-sizes="auto"
          className="lazyload blur-up"
          src="https://res.cloudinary.com/lagexpress/image/upload/w_20,f_auto,q_auto/strapi/tapalibrodelamanoconelarte_74f353c4cb.jpg"
          data-src="https://res.cloudinary.com/lagexpress/image/upload/w_auto,f_auto,q_auto,r_25/strapi/tapalibrodelamanoconelarte_74f353c4cb.jpg"
          alt="Libro 'DE LA MANO CON EL ARTE' por Eduardo Ceballos (no disponible)"
        />

        <img
          data-sizes="auto"
          className="lazyload blur-up"
          src="https://res.cloudinary.com/lagexpress/image/upload/w_20,f_auto,q_auto/strapi/tapalibrologos1985_b12705f10d.jpg"
          data-src="https://res.cloudinary.com/lagexpress/image/upload/w_auto,f_auto,q_auto,r_25/strapi/tapalibrologos1985_b12705f10d.jpg"
          alt="'LOGOS - revista libro semestral' editado por el Instituto Cultural Andino (no disponible)"
        />

        <img
          data-sizes="auto"
          className="lazyload blur-up"
          src="https://res.cloudinary.com/lagexpress/image/upload/w_20,f_auto,q_auto/strapi/tapalibrologos1986_f2ee3656db.jpg"
          data-src="https://res.cloudinary.com/lagexpress/image/upload/w_auto,f_auto,q_auto,r_25/strapi/tapalibrologos1986_f2ee3656db.jpg"
          alt="'LOGOS - revista libro semestral' editado por el Instituto Cultural Andino (no disponible)"
        />

        <img
          data-sizes="auto"
          className="lazyload blur-up"
          src="https://res.cloudinary.com/lagexpress/image/upload/w_20,f_auto,q_auto/strapi/tapalibrologos1998_b710216b91.jpg"
          data-src="https://res.cloudinary.com/lagexpress/image/upload/w_auto,f_auto,q_auto,r_25/strapi/tapalibrologos1998_b710216b91.jpg"
          alt="'LOGOS - revista libro semestral' editado por el Instituto Cultural Andino (no disponible)"
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    strapi {
      librosTiendas {
        id
        uid
        libro
        orden
        prologo
        preview
        descripcion
        libroDigital
        libroFisico
        precioD
        precioF
        tapa {
          id
          name
          caption
          alternativeText
          provider_metadata
        }
        tapa2 {
          id
          name
          caption
          alternativeText
          provider_metadata
        }
      }
    }
  }
`

export default LibrosIndex
